<template>
  <!--begin::details View-->
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <!--begin::Card header-->
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    @invalid-submit="onInvalidSubmit"
    v-slot="{ values }"
  >
    <div class="accordion" id="accordionAnagraficaImpianto">
      <form autocomplete="off">
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaImpianto-headingOne"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaImpianto-collapseOne"
                aria-expanded="true"
                aria-controls="accordionAnagraficaImpianto-collapseOne"
              >
                Dati Impianto
              </button>
            </h6>
            <div
              id="accordionAnagraficaImpianto-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaImpianto-headingOne"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Denominazione*</label
                        >
                        <TextInput name="denominazione" type="text" />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Tipologia*</label>
                        <SelectInput
                          :options="tipologieImpianti"
                          name="tipo_impianto"
                          :value="null"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Proprietà*</label>
                        <SelectInput
                          :options="proprietaImpianti"
                          name="tipo_proprieta"
                          :value="null"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">{{
                          fromEnteAggregato || values.ente_aggregato
                            ? "Data costruzione impianto aggregato"
                            : "Data costruzione impianto"
                        }}</label>
                        <Datepicker
                          v-model="data_costruzione_impianto"
                          name="data_costruzione_impianto"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          calendarCellClassName="fontData"
                          autoApply
                          :maxDate="new Date()"
                        />
                        <Field
                          name="data_costruzione_impianto"
                          v-model="data_costruzione_impianto"
                          type="hidden"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_Scadenza_Permesso_Soggiorno"
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-sm-3 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Ente aggregato:</label
                        >
                        <div class="pt-2">
                          <!-- {{ impianto.ente_aggregato }} -->
                          <TextInput
                            name="ente_aggregato"
                            type="checkbox"
                            :disabled="fromEnteAggregato"
                            :checked="fromEnteAggregato"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaImpianto-headingTwo"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaImpianto-collapseTwo"
                aria-expanded="true"
                aria-controls="accordionAnagraficaImpianto-collapseTwo"
              >
                Ubicazione
              </button>
            </h6>
            <div
              id="accordionAnagraficaImpianto-collapseTwo"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaImpianto-headingTwo"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Provincia*</label>
                        <SelectInput
                          :options="province"
                          name="id_provincia"
                          :value="null"
                          @changeSelect="changeselectedProvinciaP($event)"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Comune*</label>

                        <!--<SelectInput
                        :options="comuni"
                        name="id_comune"
                        :disabled="selectedProvinciaL ? false : true"
                      />-->

                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          @input="
                            inputFocus = 'comuni';
                            spliceComuniIstat();
                            setComuni();
                          "
                          v-model="comuneString"
                          @keyup.delete="
                            spliceComuniIstat();
                            id_comune = '';
                          "
                          :disabled="selectedProvincia ? false : true"
                          autocomplete="off"
                        />
                        <Field
                          name="id_comune"
                          type="hidden"
                          v-model="id_comune"
                          class="form-control"
                        />
                        <ListComuni
                          v-if="inputFocus === 'comuni'"
                          :list="comuniIstat"
                          @hideList="
                            spliceComuniIstat();
                            inputFocus = null;
                          "
                          @selectedElement="
                            comuneString = $event.name;
                            spliceComuniIstat();
                            id_comune = $event.value;
                            inputFocus = null;
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_comune"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <label class="fw-bold text-gray-600">Indirizzo*</label>
                        <TextInput name="indirizzo" type="text" />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">N° Civico*</label>
                        <TextInput name="num_civico" type="text" />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">C.A.P.*</label>
                        <TextInput name="cap" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>

        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaImpianto-headingThree"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaImpianto-collapseThree"
                aria-expanded="true"
                aria-controls="accordionAnagraficaImpianto-collapseThree"
              >
                Contatti
              </button>
            </h6>
            <div
              id="accordionAnagraficaImpianto-collapseThree"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaImpianto-headingThree"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Telefono*</label>
                        <TextInput name="telefono" type="text" />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Fax</label>
                        <TextInput name="fax" type="text" />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">E-mail*</label>
                        <TextInput name="email" type="text" />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Sito web</label>
                        <TextInput name="web" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>

        <!--<div class="card shadow mb-7">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaImpianto-headingFour"
          >
            <button
              class="accordion-button fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaImpianto-collapseFour"
              aria-expanded="true"
              aria-controls="accordionAnagraficaImpianto-collapseFour"
            >
              Coordinate geografiche
            </button>
          </h6>
          <div
            id="accordionAnagraficaImpianto-collapseFour"
            class="accordion-collapse collapse show"
            aria-labelledby="accordionAnagraficaImpianto-headingFour"
          >
            <div class="accordion-body p-0 m-0">
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600">Latitudine</label>
                      <TextInput name="lat" type="text" />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600">Longitudine</label>
                      <TextInput name="lon" type="text" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->

        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaImpianto-headingFour"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaImpianto-collapseFive"
                aria-expanded="true"
                aria-controls="accordionAnagraficaImpianto-collapseFive"
              >
                Campi da gioco
              </button>
            </h6>
            <div
              id="accordionAnagraficaImpianto-collapseFive"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaImpianto-headingFive"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div
                    class="ps-5 pe-5 repeater-form"
                    ref="form"
                    :style="{ height: trHeight }"
                    @submit.prevent="repeateAgain"
                  >
                    <FieldArray name="campi" v-slot="{ fields, remove, push }">
                      <div v-for="(field, index) in fields" :key="index">
                        <div class="row mb-4">
                          <!-- maxCampi -->
                          <div class="col-md-2 fw-bold text-gray-700">
                            Campo {{ index + 1 }}
                          </div>
                          <div class="col-md-4">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="nome_Campo"
                              >Descrizione*</label
                            >
                            <Field
                              :name="`campi[${index}].nome_Campo`"
                              type="text"
                              class="form-control"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].nome_Campo`"
                            />
                          </div>
                          <div class="col-md-3">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="id_Disciplina"
                              >Disciplina*</label
                            >
                            <SelectInput
                              :name="`campi[${index}].id_Disciplina`"
                              :id="`campi[${index}].id_Disciplina`"
                              :options="discipline"
                            />
                          </div>
                          <div class="col-md-2"></div>
                          <div
                            class="col-md-1 fw-bold text-gray-700 text-center mt-5 text-danger"
                          >
                            <button
                              @click.prevent="
                                remove(index);
                                count.splice(index, 1);
                                changeValue = !changeValue;
                              "
                              class="badge bg-light-danger rounded fs-5"
                            >
                              <i class="bi bi-trash text-danger"></i>
                            </button>
                          </div>
                          <hr class="mb-4 mt-4 text-gray-600" />
                        </div>
                      </div>
                      <div class="fw-bold text-center">
                        <button
                          @click.prevent="
                            push({ nome_Campo: null, id_Disciplina: null })
                          "
                          class="fw-bold fs-6 text-gray-700"
                        >
                          <i class="bi bi-plus text-gray-700 fs-6"></i> aggiungi
                          campo
                        </button>
                      </div>
                    </FieldArray>
                    <!-- fine -->
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!--end::details View-->
    <div class="text-end">
      <button class="badge bg-secondary rounded blueFit fs-5">
        Salva Anagrafica
      </button>
      <!--      <span type="button" class="badge bg-light-red rounded blueFit">
        Annulla Modifiche
      </span> -->
    </div>
  </Form>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  getCurrentInstance,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import "vue3-date-time-picker/dist/main.css";
import "vue3-date-time-picker/dist/main.css";

import Datepicker from "vue3-date-time-picker";
import { format, fromUTCtoISODate } from "@/composables/formatDate";

import { Form, Field, ErrorMessage, FieldArray } from "vee-validate";
import * as yup from "yup";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import TextInput from "../../components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "../../components/components-fit/utility/inputs/SelectInput.vue";
import ListComuni from "../../components/components-fit/utility/ListComuni.vue";

import { useStore } from "vuex";

import { addImpianto } from "../../requests/addImpianto";

import { useRouter } from "vue-router";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "add-impianto",
  emits: ["refreshList"],
  props: {
    fromModal: { type: Boolean, default: false },
    fromEnteAggregato: {
      type: Boolean,
    },
  },
  components: {
    TextInput,
    SelectInput,
    Form,
    ListComuni,
    Field,
    ErrorMessage,
    FieldArray,
    Loading,
    Datepicker,
  },
  setup(props, { emit }) {
    const store = useStore();
    //routing
    const router = useRouter(); //usato per redirect
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const tipologieImpianti = computed(() =>
      store.getters.getStateFromName("tipo_impianto")
    );
    const proprietaImpianti = computed(() =>
      store.getters.getStateFromName("tipo_proprieta")
    );
    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const keys = ref("ti|tp|ip|d");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (
      !tipologieImpianti.value ||
      !proprietaImpianti.value ||
      !province.value ||
      (province.value && !province.value.length) ||
      !discipline.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const boolAggiungiCampo = ref(false);
    const comuneString = ref("");
    const selectedProvincia = ref(false);
    const comuneP = ref("");
    const id_provincia = ref("");
    const id_comune = ref(0);
    const data_costruzione_impianto = ref(null);
    const changeselectedProvinciaP = (e) => {
      comuneString.value = "";
      if (e === undefined || e === null || e === "-") {
        selectedProvincia.value = false;
        comuneP.value = "";
        id_provincia.value = null;
      } else {
        selectedProvincia.value = true;
        comuneP.value = "";
        id_provincia.value = parseInt(e);
      }
    };
    const setComuni = () => {
      if (comuneString.value.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString.value,
            codp: id_provincia.value,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    const inputFocus = ref("");

    const schema = yup.object().shape({
      denominazione: yup.string().required("Obbligatorio"),
      tipo_impianto: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value)),
      tipo_proprieta: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_provincia: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value)),
      indirizzo: yup.string().required("Obbligatorio"),
      num_civico: yup.string().required("Obbligatorio"),
      cap: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .min(5, "Inserire un CAP valido")
        .max(5, "Inserire un CAP valido"),
      telefono: yup.string().required("Obbligatorio"),
      email: yup
        .string()
        .email("Inserire un email valida")
        .required("Obbligatorio"),
      id_comune: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .moreThan(0, "Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      campi: yup.array().of(
        yup.object({
          id_Disciplina: yup
            .number()
            .required("Obbligatorio")
            .transform((value) => (isNaN(value) ? undefined : value)),
          nome_Campo: yup.string().required("Obbligatorio").nullable(),
        })
      ),
    });
    const isLoading = ref(false);
    const onSubmit = async (values, { resetForm }) => {
      if (props.fromEnteAggregato) values.ente_aggregato = true;
      if (!values.ente_aggregato) values.ente_aggregato = false;
      isLoading.value = true;
      const impiantoAdd = addImpianto(values);
      await impiantoAdd
        .then((value) => {
          if (value) {
            const confirmButtonText = `<span
            class="btn fw-bold btn-light-danger"
            data-bs-toggle="modal"
            data-bs-target="#modal_add-anagrafica-impianto"
          >
            Ok
            </span>`;
            Swal.fire({
              html: "Impianto aggiunto con successo",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: props.fromModal ? confirmButtonText : "OK",
              customClass: {
                confirmButton:
                  "me-3 badge bg-light-danger rounded text-danger fs-5",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                if (props.fromModal) emit("refreshList");
                else router.push({ name: "impianti" });
              }
              comuneString.value = "";
              resetForm();
            });
            // router.push({ name: "impianti" });
          } else {
            const strMsg = ref(
              "<b>Si è verificato un errore</b><br>Riprovare più tardi<br><br>"
            );
            Swal.fire({
              html: strMsg.value,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        })
        .catch((err) => {
          const strMsg = ref(
            "<b>Si è verificato un errore</b><br>Riprovare più tardi<br><br>"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      isLoading.value = false;
    };

    const onInvalidSubmit = (v) => {
      console.log(v);
      const strMsg = "Compila i campi obbligatori";

      Swal.fire({
        html: strMsg,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    };

    //watch changes di stagione per resettare gli oggetti!!

    const count = ref([]);
    const changeValue = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Aggiungi Impianto", ["Lista Impianti"]);
    });

    return {
      count,
      tipologieImpianti,
      proprietaImpianti,
      province,
      changeselectedProvinciaP,
      comuneString,
      selectedProvincia,
      comuneP,
      id_provincia,
      setComuni,
      spliceComuniIstat,
      inputFocus,
      onSubmit,
      id_comune,
      schema,
      onInvalidSubmit,
      discipline,
      boolAggiungiCampo,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      changeValue,
      isLoading,
      format,
      data_costruzione_impianto,
      fromUTCtoISODate,
    };
  },
});
</script>
